import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Services from "./Pages/Services";
import PortfolioPage from "./Pages/PortfolioPage";
import ContactUs from "./Pages/ContactUs";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import DigitalService from "./Pages/DigitalService";
import UiDesignService from "./Pages/UiDesign";
import CloudAutomationDevOps from "./Pages/CloudAutomationDevOps";
import MobileApplication from "./Pages/MobileApplication";
import CustomSoftware from "./Pages/CustomSoftware";
import WebsiteDevelopment from "./Pages/WebsiteDevelopment";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/services/website-development" element={<WebsiteDevelopment/>} />
        <Route path="/services/digital-marketing" element={<DigitalService/>} />
        <Route path="/services/ui-ux-graphic-design" element={<UiDesignService/>} />
        <Route path="/services/mobile-application" element={<MobileApplication/>} />
        <Route path="/services/custom-software" element={<CustomSoftware/>} />
        <Route path="/services/cloud-automation-devops" element={<CloudAutomationDevOps/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
