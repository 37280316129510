import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import { BsInstagram, BsFacebook, BsLinkedin } from "react-icons/bs";
import logoImg from "../../assets/Images/logo.svg";

const Footer = () => {
  return (
    <div className="sipnotech__footer">
      <div className="sipnotech__footer-links">
        <div className="sipnotech__footer-links_logo_about_contact_container">
          <div
            onClick={() => window.scrollTo(0, 0)}
            className="sipnotech__footer-links_logo_div"
          >
            <img
              className="sipnotech__navbar-links_logoImg"
              src={logoImg}
              alt=""
            />
            <h1 className="sipnotech__footer-links_logo">
              Sipno<span>tech</span>
            </h1>
          </div>
          <p className="sipnotech__footer-links_logo_about_text">
            Welcome to Sipnotech solutions. We are passionate and creative crew
            thriving towards providing better it solutions for your amazing
            Businesses.
          </p>
          <div className="sipnotech__footer-links_contact_container">
            <div className="sipnotech__footer-links_contact_container_phone_container">
              <h4 className="sipnotech__footer-links_contact_container_phone">
                Contact
              </h4>
              <p className="sipnotech__footer-links_contact_container_phone_number">
                Phone No: +91-7013003526
              </p>
              
            </div>
            <div className="sipnotech__footer-links_contact_container_email_container">
              <h4 className="sipnotech__footer-links_contact_container_email">
                Email ID
              </h4>
              <p className="sipnotech__footer-links_contact_container_emailId">
                <a href="info@sipnotech.com">info@sipnotech.com</a>
              </p>
            </div>
          </div>
          <div className="sipnotech__footer-links_social_container">
            <h4>FOLLOW US ON</h4>
            <div className="sipnotech__footer-links_contact_container_social_icons">
              <BsInstagram />
              <BsFacebook />
              <BsLinkedin />
            </div>
          </div>
        </div>
        <div className="sipnotech__footer-services_container">
          <h3>SERVICES</h3>
          <li className="sipnotech__footer_li">
            <Link to="/services/mobile-application">
              Mobile Application Development
            </Link>
          </li>
          <li className="sipnotech__footer_li">
            <Link to="/services/ui-ux-graphic-design">
              UI/UX & Graphic Design
            </Link>
          </li>
          <li className="sipnotech__footer_li">
            <Link to="/services/custom-software">
              Custom Software Develoment
            </Link>
          </li>
          <li className="sipnotech__footer_li">
            <Link to="/services/website-development">Website Development</Link>
          </li>
          <li className="sipnotech__footer_li">
            <Link to="/services/digital-marketing">Digital Marketing</Link>
          </li>
          <li className="sipnotech__footer_li">
            <Link to="/services/cloud-automation-devops">
              Cloud automation and DevOps
            </Link>
          </li>
        </div>
        <div className="sipnotech__footer-company_container">
          <h3>COMPANY</h3>
          <li
            onClick={() => window.scrollTo(0, 0)}
            className="sipnotech__footer_li"
          >
            <Link to="/services">Services</Link>
          </li>
          <li
            onClick={() => window.scrollTo(0, 0)}
            className="sipnotech__footer_li"
          >
            Feature
          </li>
          <li
            onClick={() => window.scrollTo(0, 0)}
            className="sipnotech__footer_li"
          >
            Our Team
          </li>
          <li
            onClick={() => window.scrollTo(0, 0)}
            className="sipnotech__footer_li"
          >
            <Link to="/portfolio">Portfolio</Link>
          </li>
          <li
            onClick={() => window.scrollTo(0, 0)}
            className="sipnotech__footer_li"
          >
            Blog
          </li>
          <li
            onClick={() => window.scrollTo(0, 0)}
            className="sipnotech__footer_li"
          >
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li
            onClick={() => window.scrollTo(0, 0)}
            className="sipnotech__footer_li"
          >
            <Link to="/about-us">About Us</Link>
          </li>
          <li
            onClick={() => window.scrollTo(0, 0)}
            className="sipnotech__footer_li"
          >
            <Link to="/contact-us">Contact Us</Link>
          </li>
        </div>
        <div className="sipnotech__footer-newsletter_container">
          <h3>JOIN A NEWSLETTER</h3>
          <li className="sipnotech__footer_li">Your Email Id</li>
          <input
            className="sipnotech__footer-newsletter_input"
            type="email"
            placeholder="Enter your email id ⇾"
          />
        </div>
        <h3 className="sipnotech__footer-careers">CAREERS</h3>
      </div>
      <h3 className="sipnotech__footer_rights">Copyrights@2024 . All Rights Reserved</h3>
    </div>
  );
};

export default Footer;
