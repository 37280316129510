const ParagraphData = [
  {
    id: 1,
    category: "paragraphPage",
    title: "Website Development",
    desc: "Sipnotech Solutions offers a comprehensive website development service that includes website design and development, search engine optimization, content management systems, hosting and maintenance, e-commerce solutions and more. Our team of experienced web developers and designers can help you create a website from scratch or customize an existing design to better match your business needs. We also offer custom website development services to ensure that your website is tailored to meet the specific needs of your business. Our website development process follows best practices and guidelines to ensure that your website is secure, user-friendly and optimized for search engines. We also provide ongoing website maintenance and support services, so that you can keep your website up to date and secure.",
  },
  {
    id: 2,
    category: "paragraphPage",
    title: "Digital Marketing",
    desc: "Digital marketing at Sipnotech Solutions includes activities such as SEO (Search Engine Optimization), Linkedin Marketing, content marketing, pay-per-click (PPC) advertising, social media marketing, email marketing, and more. By leveraging the power of the internet and digital technologies, Sipnotech Solutions can help businesses to reach more potential customers and build relationships with them. This helps businesses to increase their brand awareness and sales.",
  },
  {
    id: 3,
    category: "paragraphPage",
    title: "UI/UX Design",
    desc: "At Sipnotech Solutions we offer professional UI/UX and graphic designing services for websites, mobile applications, software, and other digital products. Our team of experienced designers will work with you to create beautiful and functional designs that meet the needs of your users and enhance the overall user experience. We use a variety of tools and techniques to create engaging designs that capture the attention of your target audience. We also specialize in responsive web design, which ensures that your website looks great on any device or screen size. Additionally, we offer branding and logo design services to help you create a unique and recognizable brand identity. With our services you can be sure to get the best design solutions for your digital products.",
  },
  {
    id: 4,
    category: "paragraphPage",
    title: "Mobile Application Development",
    desc: "We are a Software development company that specializes in the development of custom mobile applications for both Android and iOS platforms. We provide complete mobile application development services, including app design and development, custom UI/UX design, integration with third-party APIs, and ongoing support and maintenance. We also offer mobile app testing and optimization, helping to ensure that your app meets the highest standards of performance, stability, and security. Our experienced development team has a deep understanding of the different mobile platforms and can create custom solutions that are tailored to meet your specific needs. We use the latest technologies, frameworks, and tools to ensure the highest quality and performance of your mobile application.",
  },
  {
    id: 5,
    category: "paragraphPage",
    title: "Custom Software Development",
    desc: "Custom Software Development is a process of creating software applications tailored to the specific needs and requirements of an individual or business. The process involves developing a software solution from the ground up, with the ultimate goal of satisfying the customer's exact requirements. Custom software can be developed for any type of business, from small businesses to large enterprises. Sipnotech is a custom software development company which specializes in the development of applications tailored to the exact needs of the clients. The team of experienced developers have the capability to understand the clients' requirements and develop bespoke software solutions that are tailored to the exact requirements of the clients. The team at Sipnotech also provides maintenance, support and updates to the applications.",
  },
  {
    id: 6,
    category: "paragraphPage",
    title: "Cloud automation and DevOps",
    desc: "Sipnotech Solutions is a leading provider of cloud automation and DevOps services. We provide a comprehensive suite of DevOps and Cloud Automation services to help organizations move their applications and workloads to the cloud.",
    desc1:
      "Our services cover the entire DevOps and Cloud Automation life cycle, from planning and design to deployment and management. We help our customers to optimize their cloud environment and reduce costs by automating the management of cloud services.  ",
    desc2: (
      <p>
        Our Cloud Automation & DevOps services include: <br />
        • Cloud Infrastructure Automation: Automate the provisioning, management
        and monitoring of cloud resources and services. <br />• Application
        Lifecycle Management (ALM): Automate the build, deployment and
        management of applications across different environments. <br />•
        Continuous Delivery (CD): Automate the process of releasing software to
        production by integrating with automated testing and deployment tools.{" "}
        <br />• DevOps Automation: Automate the development, testing and
        deployment of applications in a continuous delivery pipeline. <br />•
        Containerization: Automate the deployment and management of
        container-based applications and services. <br />• Security Automation:
        Automate the security and compliance of cloud environments. <br />•
        Monitoring & Reporting: Automate the monitoring, performance and
        scalability of cloud environments. We provide a comprehensive suite of
        services that enable organizations to adopt DevOps and Cloud Automation
        best practices and processes. Our experts are skilled in leveraging the
        latest technologies, such as Infrastructure-as-Code (IaC),
        Infrastructure Automation, Infrastructure-as-a-Service (IaaS), and
        DevOps tools, to enable organizations to move their workloads to the
        cloud quickly and efficiently. We help our customers to reduce costs,
        accelerate application and workload delivery, and increase their agility
        and scalability.
      </p>
    ),
  },
];

export default ParagraphData;
