import React from "react";
import "./what-we-do.css";
import Card from "../../Common/Card/Card";
import data from "../../Data/WhatWeDoData";
import { useNavigate } from "react-router-dom";
import peopleImg from "../../assets/Images/whatwedo/people.svg";

const What_we_do = () => {
  const navigate = useNavigate();

  return (
    <div className="sipnotech__what-we-do">
      <h1 className="Sipnotech__what-we-do_heading">
        What Can We
        <br />
        Do For You?
      </h1>
      <img
        className="sipnotech__what-we-do_people-img-mobile"
        src={peopleImg}
        alt=""
      />

      <div className="sipnotech__what-we-do_cards">
        <div className="sipnotech__what-we-do_cards_first-div">
          <Card
            key={data[3].id}
            onClick={() => navigate(`/services/${data[3].route}`)}
            className="sipnotech__what-we-do_cards-card"
          >
            <img
              className="sipnotech__what-we-do_cards-card_img"
              src={data[3].image}
              alt=""
            />
            <h1 className="sipnotech__what-we-do_cards_title">
              {data[3].title}
            </h1>
            <p className="sipnotech__what-we-do_cards_desc">{data[3].desc}</p>
            <button className="sipnotech__what-we-do_cards-btn">
              {data[3].button}
              <span className="sipnotech__what-we-do_cards-btn_icon">
                {data[3].icon}
              </span>
            </button>
          </Card>
          <img
            className="sipnotech__what-we-do_people-img"
            src={peopleImg}
            alt=""
          />
          <Card
            key={data[5].id}
            onClick={() => navigate(`/services/${data[5].route}`)}
            className="sipnotech__what-we-do_cards-card"
          >
            <img
              className="sipnotech__what-we-do_cards-card_img"
              src={data[5].image}
              alt=""
            />
            <h1 className="sipnotech__what-we-do_cards_title">
              {data[5].title}
            </h1>
            <p className="sipnotech__what-we-do_cards_desc">{data[5].desc}</p>
            <button className="sipnotech__what-we-do_cards-btn">
              {data[5].button}
              <span className="sipnotech__what-we-do_cards-btn_icon">
                {data[5].icon}
              </span>
            </button>
          </Card>
        </div>
        {/* ////// */}
        <div className="sipnotech__what-we-do_cards_second-div">
          <Card
            key={data[0].id}
            onClick={() => navigate(`/services/${data[0].route}`)}
            className="sipnotech__what-we-do_cards-card"
          >
            <img
              className="sipnotech__what-we-do_cards-card_img"
              src={data[0].image}
              alt=""
            />
            <h1 className="sipnotech__what-we-do_cards_title">
              {data[0].title}
            </h1>
            <p className="sipnotech__what-we-do_cards_desc">{data[0].desc}</p>
            <button className="sipnotech__what-we-do_cards-btn">
              {data[0].button}
              <span className="sipnotech__what-we-do_cards-btn_icon">
                {data[0].icon}
              </span>
            </button>
          </Card>
          <Card
            key={data[2].id}
            onClick={() => navigate(`/services/${data[2].route}`)}
            className="sipnotech__what-we-do_cards-card"
          >
            <img
              className="sipnotech__what-we-do_cards-card_img"
              src={data[2].image}
              alt=""
            />
            <h1 className="sipnotech__what-we-do_cards_title">
              {data[2].title}
            </h1>
            <p className="sipnotech__what-we-do_cards_desc">{data[2].desc}</p>
            <button className="sipnotech__what-we-do_cards-btn">
              {data[2].button}
              <span className="sipnotech__what-we-do_cards-btn_icon">
                {data[2].icon}
              </span>
            </button>
          </Card>
        </div>
        {/* ////// */}
        <div className="sipnotech__what-we-do_cards_third-div">
          <Card
            key={data[4].id}
            onClick={() => navigate(`/services/${data[4].route}`)}
            className="sipnotech__what-we-do_cards-card"
          >
            <img
              className="sipnotech__what-we-do_cards-card_img"
              src={data[4].image}
              alt=""
            />
            <h1 className="sipnotech__what-we-do_cards_title">
              {data[4].title}
            </h1>
            <p className="sipnotech__what-we-do_cards_desc">{data[4].desc}</p>
            <button className="sipnotech__what-we-do_cards-btn">
              {data[4].button}
              <span className="sipnotech__what-we-do_cards-btn_icon">
                {data[4].icon}
              </span>
            </button>
          </Card>
          <Card
            key={data[1].id}
            onClick={() => navigate(`/services/${data[1].route}`)}
            className="sipnotech__what-we-do_cards-card"
          >
            <img
              className="sipnotech__what-we-do_cards-card_img"
              src={data[1].image}
              alt=""
            />
            <h1 className="sipnotech__what-we-do_cards_title">
              {data[1].title}
            </h1>
            <p className="sipnotech__what-we-do_cards_desc">{data[1].desc}</p>
            <button className="sipnotech__what-we-do_cards-btn">
              {data[1].button}
              <span className="sipnotech__what-we-do_cards-btn_icon">
                {data[1].icon}
              </span>
            </button>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default What_we_do;

// import React from "react";
// import "./what-we-do.css";
// import Card from "../../Common/Card/Card";
// import data from "../../Data/WhatWeDoData";
// import { useNavigate } from "react-router-dom";

// const What_we_do = () => {
//   const navigate = useNavigate();

//   return (
//     <div className="sipnotech__what-we-do">
//       <h1 className="Sipnotech__what-we-do_heading">
//         What Can We
//         <br />
//         Do For You?
//       </h1>
//       <div className="sipnotech__what-we-do_cards">
//         {data.map((item) => (
//           <Card
//             key={item.id}
//             onClick={() => navigate(`/services/${item.route}`)}
//             className="sipnotech__what-we-do_cards-card"
//           >
//             <img
//               className="sipnotech__what-we-do_cards-card_img"
//               src={item.image}
//               alt=""
//             />
//             <h1 className="sipnotech__what-we-do_cards_title">{item.title}</h1>
//             <p className="sipnotech__what-we-do_cards_desc">
//               {item.desc}
//             </p>
//             <button className="sipnotech__what-we-do_cards-btn">
//               {item.button}
//               <span className="sipnotech__what-we-do_cards-btn_icon">
//                 {item.icon}
//               </span>
//             </button>
//           </Card>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default What_we_do;
