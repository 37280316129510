// home data------
import homeHeroImg from "../assets/Images/HomePage/Hero/homeHeroImg.svg";
import vector from "../assets/Images/HomePage/Hero/vector.png";
import lace1 from "../assets/Images/HomePage/Hero/lace.png";
import lace2 from "../assets/Images/HomePage/Hero/lace.png";
import lace3 from "../assets/Images/HomePage/Hero/lace.png";
// ----------

// about data------
import aboutHeroImg from "../assets/Images/About/Hero/aboutHeroImg.svg";
// -------

// services data------
import servicesHeroImg from "../assets/Images/Services/Hero/servicesHeroImg.svg";
// -------

// portfolio data------
import portfolioHeroImg from "../assets/Images/PortfolioPage/Hero/portfolioHeroImg.svg";
// -------

// contact data------
import contactHeroImg from "../assets/Images/ContactPage/Hero/contactHeroImg.png";
// -------
import development from "../assets/Images/Services/development.svg";
import marketing from "../assets/Images/Services/marketing.svg";
import design from "../assets/Images/Services/design.svg";
import cloud from "../assets/Images/Services/cloud.svg";
import mobile from "../assets/Images/Services/mobile.svg";
import custom from "../assets/Images/Services/custom.svg";

const heroData = [
  {
    id: 1,
    category: "homePage",
    img1: homeHeroImg,
    img2: vector,
    img3: lace1,
    img4: lace2,
    img5: lace3,

    title: (
      <h1 className="sipnotech__hero-text_title">
        Digital Solutions <span></span>
        <br />
        For SMBs and Startups
      </h1>
    ),
    desc: "We help clients promote their businesses and products online, we operate across Canda, EU and India. Services can include search engine optimization (SEO), social media marketing, content marketing, website design and development, email marketing, and more. we help businesses reach a wider audience,increase their visibility online, and engage customers more effectively.",
    discoverMoreBtn: (
      <button className="sipnotech__hero-btns_discoverMoreBtn">
        Discover More
      </button>
    ),
    getStartedBtn: (
      <button className="sipnotech__hero-btns_getStartedBtn">
        Get Started
      </button>
    ),
  },
  {
    id: 2,
    category: "aboutPage",
    img1: aboutHeroImg,
    img2: vector,
    title: (
      <h1 className="sipnotech__hero-text_title">
        Help Companies
        <br />
        <span>Design</span> Their
        <br />
        <span>Products</span>
      </h1>
    ),
    desc: "We are committed to providing our customers with exceptional service while offering our employees the best training.",
  },
  {
    id: 3,
    category: "servicesPage",
    img1: servicesHeroImg,
    img2: vector,
    title: (
      <h1 className="sipnotech__hero-text_title">
        The <span>Best Solutions</span>
        <br />
        For Your Business
      </h1>
    ),
    desc: "We are committed to providing our customers with exceptional service while offering our employees the best training.",
  },
  {
    id: 4,
    category: "portfolioPage",
    img1: portfolioHeroImg,
    img2: vector,
    title: (
      <h1 className="sipnotech__hero-text_title">
        Our <span>Projects</span>
      </h1>
    ),
    desc: "We have a proven track record of delivering high-quality projects that solve real-world problems for clients across various industries. We specialize in web and mobile app development, custom software solutions, cloud based services etc. Check out our portfolio to see some of our past work and how we can help bring your vision to life.",
  },
  {
    id: 5,
    category: "contactPage",
    img1: contactHeroImg,
    img2: vector,
    title: (
      <h1 className="sipnotech__hero-text_title">
        <span>Contact</span> Us
      </h1>
    ),
    desc: "Lets Connect NOW…!",
    desc2:
      "We are always here to assist you with your technology needs. Our team of experts is dedicated to providing you with the highest level of customer service and support. Whether you have a question about our products and services or need help troubleshooting a problem, we are here to help. Don't hesitate to reach out to us via the available contact channels. We look forward to hearing from you and helping you achieve your technology goals.",
  },
  {
    id: 6,
    category: "developmentServices",
    img1: development,
    title: (
      <h1 className="sipnotech__hero-text_title">
        <span>Website</span>
        <br />
        Development
      </h1>
    ),
    desc: "We are committed to providing our customers with exceptional service while offering our employees the best training.    ",
  },
  {
    id: 7,
    category: "digitalServices",
    img1: marketing,
    title: (
      <h1 className="sipnotech__hero-text_title">
        <span>Digital Marketing</span>
        <br />
        Services
      </h1>
    ),
    desc: "We are committed to providing our customers with exceptional service while offering our employees the best training.    ",
  },
  {
    id: 8,
    category: "digitalServices",
    img1: design,
    title: (
      <h1 className="sipnotech__hero-text_title">
        <span>Ui/Ux & Graphic</span>
        <br />
        Designing
      </h1>
    ),
    desc: "We are committed to providing our customers with exceptional service while offering our employees the best training.    ",
  },
  {
    id: 9,
    category: "mobile",
    img1: mobile,
    title: (
      <h1 className="sipnotech__hero-text_title">
        <span>Mobile Application</span>
        <br />
        Development
      </h1>
    ),
    desc: "We are committed to providing our customers with exceptional service while offering our employees the best training.    ",
  },
  {
    id: 10,
    category: "custom",
    img1: custom,
    title: (
      <h1 className="sipnotech__hero-text_title">
        <span>Custom Software</span>
        <br />
        Development
      </h1>
    ),
    desc: "We are committed to providing our customers with exceptional service while offering our employees the best training.    ",
  },
  {
    id: 11,
    category: "cloudAutomation",
    img1: cloud,
    title: (
      <h1 className="sipnotech__hero-text_title">
        <span>Cloud Automation</span>
        <br />
        and DevOps
      </h1>
    ),
    desc: "We are committed to providing our customers with exceptional service while offering our employees the best training.    ",
  },
];

export default heroData;
