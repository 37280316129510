import React from "react";
import "./PrivacyPolicyContent.css";

const PrivacyPolicyContent = () => {
  return (
    <div className="sipnotech_privacy-policy_content-main">
      <p className="sipnotech_privacy-policy_title">1. Terms</p>
      <p className="sipnotech_privacy-policy_desc">
        By accessing this web site, you are agreeing to be bound by these web
        site Terms and Conditions of Use, applicable laws and regulations and
        their compliance. If you disagree with any of the stated terms and
        conditions, you are prohibited from using or accessing this site. The
        materials contained in this site are secured by relevant copyright and
        trade mark law.
      </p>
      <p className="sipnotech_privacy-policy_title">2. Use License</p>
      <p className="sipnotech_privacy-policy_desc">
        a. Permission is allowed to temporarily download one duplicate of the
        materials (data or programming) on SIPNOTECH SOLUTIONS PRIVATE LIMITED's
        site for individual and non-business use only. This is the just a permit
        of license and not an exchange of title, and under this permit you may
        not:
        <p className="sipnotech_privacy-policy_bullet">
          i. modify or copy the materials;
        </p>
        <p className="sipnotech_privacy-policy_bullet">
          ii. use the materials for any commercial use , or for any public
          presentation (business or non-business);
        </p>
        <p className="sipnotech_privacy-policy_bullet">
          iii. attempt to decompile or rebuild any product or material contained
          on SIPNOTECH SOLUTIONS PRIVATE LIMITED's site;
        </p>
        <p className="sipnotech_privacy-policy_bullet">
          iv. remove any copyright or other restrictive documentations from the
          materials; or
        </p>
        <p className="sipnotech_privacy-policy_bullet">
          v. transfer the materials to someone else or even "mirror" the
          materials on other server.
        </p>
      </p>
      <p className="sipnotech_privacy-policy_desc">
        b. This permit might consequently be terminated if you disregard any of
        these confinements and may be ended by SIPNOTECH SOLUTIONS PRIVATE
        LIMITED whenever deemed. After permit termination or when your viewing
        permit is terminated, you must destroy any downloaded materials in your
        ownership whether in electronic or printed form.
      </p>
      <p className="sipnotech_privacy-policy_title">3. Disclaimer</p>
      <p className="sipnotech_privacy-policy_desc">
        a. The materials on SIPNOTECH SOLUTIONS PRIVATE LIMITED's site are given
        "as is". SIPNOTECH SOLUTIONS PRIVATE LIMITED makes no guarantees,
        communicated or suggested, and thus renounces and nullifies every single
        other warranties, including without impediment, inferred guarantees or
        states of merchantability, fitness for a specific reason, or
        non-encroachment of licensed property or other infringement of rights.
        Further, SIPNOTECH SOLUTIONS PRIVATE LIMITED does not warrant or make
        any representations concerning the precision, likely results, or
        unwavering quality of the utilization of the materials on its Internet
        site or generally identifying with such materials or on any destinations
        connected to this website.
      </p>
      <p className="sipnotech_privacy-policy_title">4. Constraints</p>
      <p className="sipnotech_privacy-policy_desc">
        In no occasion should SIPNOTECH SOLUTIONS PRIVATE LIMITED or its
        suppliers subject for any harms (counting, without constraint, harms for
        loss of information or benefit, or because of business interference,)
        emerging out of the utilization or powerlessness to utilize the
        materials on SIPNOTECH SOLUTIONS PRIVATE LIMITED's Internet webpage,
        regardless of the possibility that SIPNOTECH SOLUTIONS PRIVATE LIMITED
        or a SIPNOTECH SOLUTIONS PRIVATE LIMITED approved agent has been told
        orally or in written of the likelihood of such harm. Since a few
        purviews don't permit constraints on inferred guarantees, or impediments
        of obligation for weighty or coincidental harms, these confinements may
        not make a difference to you.
      </p>
      <p className="sipnotech_privacy-policy_title">5. Amendments and Errata</p>
      <p className="sipnotech_privacy-policy_desc">
        The materials showing up on SIPNOTECH SOLUTIONS PRIVATE LIMITED's site
        could incorporate typographical, or photographic mistakes. SIPNOTECH
        SOLUTIONS PRIVATE LIMITED does not warrant that any of the materials on
        its site are exact, finished, or current. SIPNOTECH SOLUTIONS PRIVATE
        LIMITED may roll out improvements to the materials contained on its site
        whenever without notification. SIPNOTECH SOLUTIONS PRIVATE LIMITED does
        not, then again, make any dedication to update the materials.
      </p>
      <p className="sipnotech_privacy-policy_title">6. Links</p>
      <p className="sipnotech_privacy-policy_desc">
        SIPNOTECH SOLUTIONS PRIVATE LIMITED has not checked on the majority of
        the websites or links connected to its website and is not in charge of
        the substance of any such connected webpage. The incorporation of any
        connection does not infer support by SIPNOTECH SOLUTIONS PRIVATE LIMITED
        of the site. Utilization of any such connected site is at the user's own
        risk.
      </p>
      <p className="sipnotech_privacy-policy_title">
        7. Site Terms of Use Modifications
      </p>
      <p className="sipnotech_privacy-policy_desc">
        SIPNOTECH SOLUTIONS PRIVATE LIMITED may update these terms of
        utilization for its website whenever without notification. By utilizing
        this site you are consenting to be bound by the then current form of
        these Terms and Conditions of Use.
      </p>
      <p className="sipnotech_privacy-policy_title">8. Governing Law</p>
      <p className="sipnotech_privacy-policy_desc">
        Any case identifying with SIPNOTECH SOLUTIONS PRIVATE LIMITED's site
        should be administered by the laws of the country of INDIA SIPNOTECH
        SOLUTIONS PRIVATE LIMITED State without respect to its contention of law
        provisions.
      </p>
      <p>General Terms and Conditions applicable to Use of a Web Site.</p>
      <p className="sipnotech_privacy-policy_title">Privacy Policy</p>
      <p className="sipnotech_privacy-policy_desc">
        Your privacy is critical to us. Likewise, we have built up this Policy
        with the end goal you should see how we gather, utilize, impart and
        reveal and make utilization of individual data. The following blueprints
        our privacy policy.
      </p>
      <p className="sipnotech_privacy-policy_bullet">
        • Before or at the time of collecting personal information, we will
        identify the purposes for which information is being collected.
      </p>
      <p className="sipnotech_privacy-policy_bullet">
        • We will gather and utilization of individual data singularly with the
        target of satisfying those reasons indicated by us and for other good
        purposes, unless we get the assent of the individual concerned or as
        required by law.
      </p>
      <p className="sipnotech_privacy-policy_bullet">
        • We will just hold individual data the length of essential for the
        satisfaction of those reasons.
      </p>
      <p className="sipnotech_privacy-policy_bullet">
        • We will gather individual data by legal and reasonable means and,
        where fitting, with the information or assent of the individual
        concerned.
      </p>
      <p className="sipnotech_privacy-policy_bullet">
        • Personal information ought to be important to the reasons for which it
        is to be utilized, and, to the degree essential for those reasons, ought
        to be exact, finished, and updated.
      </p>
      <p className="sipnotech_privacy-policy_bullet">
        • We will protect individual data by security shields against misfortune
        or burglary, and also unapproved access, divulgence, duplicating, use or
        alteration.
      </p>
      <p className="sipnotech_privacy-policy_bullet">
        • We will promptly provide customers with access to our policies and
        procedures for the administration of individual data.
      </p>
      <p className="sipnotech_privacy-policy_desc">
        We are focused on leading our business as per these standards with a
        specific end goal to guarantee that the privacy of individual data is
        secure and maintained.
      </p>
    </div>
  );
};

export default PrivacyPolicyContent;
