import React, { useState } from "react";
import "./ServicePortfolio.css";
import Card from "../../Common/Card/Card";
import data from "../../Data/PortfolioData";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const ServicePortfolio = (props) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const category = data.map((item) => item.category);
  const uniqueCategory = ["All", ...new Set(category)];
  const [content, setContent] = useState(data);
  const [activeCategory, setActiveCategory] = useState("All");
  let count = 1;

  const displayData = (activeCat) => {
    setActiveCategory(activeCat);
    if (activeCat === "All") {
      setContent(data);
    } else {
      let filterData = data.filter(function (value) {
        return value.category === activeCat;
      });
      setContent(filterData);
    }
  };

  return (
    <div className="sipnotech__service-portfolio">
      <h1 className="sipnotech__service-portfolio_heading">
        Our Featured <span>Projects</span>
      </h1>
      <Carousel
        draggable={false}
        ssr={true}
        infinite={true}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-10-px"
        responsive={responsive}
      >
        {content.map((item) => (
          <Card
            key={item.id}
            className="sipnotech__service-portfolio_cards-card"
          >
            <img
              className="sipnotech__service-portfolio_cards-card_img"
              src={item.image}
              alt=""
            />
          </Card>
        ))}
      </Carousel>
    </div>
  );
};

export default ServicePortfolio;
