import Mobile from "../assets/Images/whatwedo/wd.png";
import Web from "../assets/Images/whatwedo/dm.png";
import Custom from "../assets/Images/whatwedo/uiux.png";
import Digital from "../assets/Images/whatwedo/gd.png";
import Ui from "../assets/Images/whatwedo/cw.png";
import Seo from "../assets/Images/whatwedo/seo.png";
import { HiArrowNarrowRight } from "react-icons/hi";

const data = [
  {
    id: 1,
    image: Web,
    title: "Website Development",
    description:
      "Website development involves creating a website including its design, coding, content, and other features. Software development involves creating software applications, including the programming code and other aspects of the application. Both activities require knowledge of computers, programming languages, and software engineering.",
    desc: "Sipnotech Solutions offers a comprehensive website development service that includes website design and development.",
    button: "View More",
    icon: <HiArrowNarrowRight />,
    route: "website-development",
  },
  {
    id: 2,
    image: Digital,
    title: "Digital Marketing",
    description:
      "It involves using techniques such as search engine optimization (SEO), content marketing,social media marketing, and email marketing to reach potential customers and drive sales.Digital marketing is becoming increasingly important in today's digital world, as more and more people are using the internet to search for products and services. As a result,businesses have to be more creative and strategic when it comes to their digital marketing strategies in order to stay ahead of the competition.",
    desc: "Digital marketing at Sipnotech Solutions includes activities such as SEO (Search Engine Optimization), Linkedin Marketing, content marketing, pay-per-click.",
    button: "View More",
    icon: <HiArrowNarrowRight />,
    route: "digital-marketing",
  },
  {
    id: 3,
    image: Ui,
    title: "Ui/Ux & Graphic Design",
    description:
      "Creating user-centered designs that focus on the user experience, making sure the user is able to interact with the product or service as efficiently and effectively as possible. This includes creating wireframes, user flows, visual designs, and prototypes. UI/UX designers need to have a strong understanding of user psychology and technology in order to create effective designs.",
    desc: "At Sipnotech Solutions we offer professional UI/UX and graphic designing services for websites, mobile applications, software, and other digital products.",
    button: "View More",
    icon: <HiArrowNarrowRight />,
    route: "ui-ux-graphic-design",

  },
  {
    id: 4,
    image: Mobile,
    title: "Mobile Application Development",
    description:
      "It includes the process of visual communication and problem-solving through the use of typography, photography, and illustration. Graphic design is an important part of the modern world and is used in everything from advertising to web design to product packaging. It can be used to create logos, posters, brochures, websites, and more. Graphic designers use a variety of tools to create visually appealing designs that communicate a message or serve a purpose.",
    desc: "We are a Software development company that specializes in the development of custom mobile applications for both Android and iOS platforms.",
    button: "View More",
    icon: <HiArrowNarrowRight />,
    route: "mobile-application",

  },
  {
    id: 5,
    image: Custom,
    title: "Custom Software Development",
    description:
      "Also known as content marketing, is the process of creating and publishing high-quality content in order to engage an audience and attract potential customers. Content writing can be used to promote a product or service, educate customers about a particular industry, or simply provide helpful and entertaining information. Content writers create content in various forms, including blog posts, articles, social media posts, and web content. They ensure the content is relevant, engaging, and informative. Content writers must also be able to research topics in order to provide accurate and up-to-date information. Additionally, they must understand how to measure the success of their content, such as by tracking website visits,social media shares, and responses from readers.",
    desc: "Custom Software Development is a process of creating software applications tailored to the specific needs and requirements of an individual or business.",
    button: "View More",
    icon: <HiArrowNarrowRight />,
    route: "custom-software",

  },
  {
    id: 6,
    image: Seo,
    title: "Cloud automation and DevOps",
    description:
      "Search engine optimization (SEO) is the process of optimizing a website or web page to increase its visibility in a search engine's unpaid search results.This is done by making changes to the content, structure, and technical aspects of the website to increase its relevancy to certain keywords and phrases, and improve its overall ranking in the search engine results. SEO is used to help people find relevant content more quickly and easily.By optimizing a website for search engine algorithms, it can increase its organic search traffic and potentially drive more qualified leads to the website.",
    desc: "We provide a comprehensive suite of DevOps and Cloud Automation services to help organizations move their applications and workloads to the cloud.",
    button: "View More",
    icon: <HiArrowNarrowRight />,
    route: "cloud-automation-devops",

  },
];

export default data;
