import React, { useEffect } from "react";
import Navbar from "../Common/Navbar/Navbar";
import Hero from "../Components/Hero/Hero";
import heroData from "../Data/HeroData";
import paragraphData from "../Data/ParagraphData";
import WorkTogether from "../Components/WorkTogether/WorkTogether";
import Footer from "../Common/Footer/Footer";
import Paragraph from "../Components/Paragraph/Paragraph";
import ServicePortfolio from "../Components/ServicePortfolio/ServicePortfolio";

const MobileApplication = () => {
  let item = heroData.find((i) => i.id === 9);
  let para = paragraphData.find((i) => i.id === 4);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <Hero title={item.title} desc={item.desc} heroImg={item.img1} />
      <Paragraph
        title={para.title}
        desc={para.desc}
        desc1={para.desc1}
        desc2={para.desc2}
      />
      <ServicePortfolio />
      <WorkTogether />
      <Footer />
    </div>
  );
};

export default MobileApplication;
