import React from "react";
import "./paragraph.css"


const Paragraph = (props) => {
  return (
    <div className="sipnotech__paragraph">
      <p className="sipnotech__paragraph_heading">{props.title}</p>
      <div className="sipnotech_paragraph_content-div">
        <p className="sipnotech_paragraph_para">{props.desc}</p>
        <p className="sipnotech_paragraph_para">{props.desc1}</p>
        <div className="sipnotech_paragraph_para">{props.desc2}</div>
      </div>
    </div>
  );
};

export default Paragraph;
