import React, { useEffect } from "react";
import Navbar from "../Common/Navbar/Navbar";
import Footer from "../Common/Footer/Footer";
import PrivacyPolicyContent from "../Components/PrivacyPolicyContent/PrivacyPolicyContent";

const PrivacyPolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <PrivacyPolicyContent />
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
